// web_tourOrder_tourTravellerExport 出游人模板导出

const __request = require(`./__request/__request_contentType_form`);

// 接口地址： http://192.168.0.15:8080/doc.html#/所有接口/tour-order-controller/getNoticeUsingPOST

export default function web_tourOrder_tourTravellerExport(pParameter) {
  if (!pParameter) pParameter = {};
  const url = pParameter.url || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/tourOrder/tourTravellerExport'
  params.data = {
    url
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}

