// web_tourOrder_tourTraveller 批量添加出游人

const __request = require(`./__request/__request_contentType_json`);

// 接口地址： http://192.168.0.15:8080/doc.html#/所有接口/tour-order-controller/TourTravellerInformationUsingPOST

export default function web_tourOrder_tourTraveller(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/tourOrder/tourTraveller'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}

