import web_tourOrder_details from '@/lib/data-service/default/web_tourOrder_details'
import web_tourOrder_tourTraveller from '@/lib/data-service/default/web_tourOrder_tourTraveller'
import web_tourOrder_tourTravellerExport from '@/lib/data-service/default/web_tourOrder_tourTravellerExport'
import web_tourOrder_tourTravellerImport from '@/lib/data-service/default/web_tourOrder_tourTravellerImport'
import web_tourismFullReduction_orderReductionMsg from '@/lib/data-service/default/web_tourismFullReduction_orderReductionMsg'
export default {
  data() {
    const checkCertificatesNumber = (rule, value, callback) => {
      if (value === '') {
        callback()
        return
      }
      for (let i = 0, l = this.adultParams.length; i < l; i++) {
        if (this.adultParams[i].certificatesNumber === value) {
          if (this.adultParams[i].certificatesType === 1) {
            // 检查身份证
            const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (reg.test(value) === false) {
              callback(new Error('身份证输入不合法'));
            } else {
              callback()
            }
          }
          break
        }
      }
      for (let i = 0, l = this.childrenParams.length; i < l; i++) {
        if (this.childrenParams[i].certificatesNumber === value) {
          if (this.childrenParams[i].certificatesType === 1) {
            // 检查身份证
            const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (reg.test(value) === false) {
              callback(new Error('身份证输入不合法'));
            } else {
              callback()
            }
          }
          break
        }
      }
      for (let i = 0, l = this.childBedParams.length; i < l; i++) {
        if (this.childBedParams[i].certificatesNumber === value) {
          if (this.childBedParams[i].certificatesType === 1) {
            // 检查身份证
            const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (reg.test(value) === false) {
              callback(new Error('身份证输入不合法'));
            } else {
              callback()
            }
          }
          break
        }
      }
      for (let i = 0, l = this.agedParams.length; i < l; i++) {
        if (this.agedParams[i].certificatesNumber === value) {
          if (this.agedParams[i].certificatesType === 1) {
            // 检查身份证
            const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (reg.test(value) === false) {
              callback(new Error('身份证输入不合法'));
            } else {
              callback()
            }
          }
          break
        }
      }
      callback()
    }
    return {
      orderId: '',
      confirmWay: '',
      tourTeamId: '',
      detailData: {},
      userNum: 0,
      userParams: [],
      adultParams: [],
      childrenParams: [],
      childBedParams: [],
      agedParams: [],
      touristGenderList: [{ value: 0, label: '男' }, { value: 1, label: '女' }],
      certificatesTypeList: [{ value: 1, label: '身份证' }, { value: 2, label: '护照' }, { value: 3, label: '军官证' }, { value: 4, label: '学生证' }, { value: 5, label: '其他' }],
      rule: {
        touristName: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        touristGender: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        certificatesType: [
          { required: true, message: '请选择证件类型', trigger: 'change' }
        ],
        certificatesNumber: [
          { required: true, message: '请输入证件号码', trigger: 'blur' },
          { validator: checkCertificatesNumber, trigger: 'blur' }
        ]
      },
      easyRule: {
        touristName: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        touristGender: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        certificatesType: [
          /*{ required: true, message: '请选择证件类型', trigger: 'change' }*/
        ],
        certificatesNumber: [
          /*{ required: true, message: '请输入证件号码', trigger: 'blur' },*/
          { validator: checkCertificatesNumber, trigger: 'blur' }
        ]
      },
      formList: [],
      excelUrl: 'http://file.yinzhilv.com/group1/M00/00/F6/wKgAZV3FO0KAB_O9AAAlrdDv_to21.xlsx?attname=出游人填写模板.xlsx',
      fileUrl: '',

      fileList: [],
      resultAmount: {}, // 最终计算金额
      resultPrice: 0,
    }
  },
  components: {},
  created() {
    this.orderId = this.$route.query.orderId
    this.confirmWay = this.$route.query.confirmWay
    this.tourTeamId = this.$route.query.tourTeamId
    this.productId = this.$route.query.productId
  },
  mounted() {
    this.getDetail()
  },
  activated() {
  },
  deactivated() { },
  destroyed() {
  },
  watch: {},
  computed: {},
  filters: {
    returnFloatFilter(item) {
      let value = Math.round(parseFloat(item) * 100) / 100;
      let xsd = value.toString().split('.');
      if (xsd.length === 1) {
        value = value.toString() + '.00';
        return value;
      }
      if (xsd.length > 1) {
        if (xsd[1].length < 2) {
          value = value.toString() + '0';
        }
        return value;
      }
    }
  },
  methods: {
    toHome() {
      this.$router.push({
        name: 'distributor-tour-front-home'
      })
      // document.documentElement.scrollTop = 0;
    },
    toSearch(query) {
      this.$router.push({
        name: 'distributor-tour-front-search',
        query
      })
      // document.documentElement.scrollTop = 0;
    },
    toDetail() {
      this.$router.push({
        name: 'distributor-tour-front-details',
        query: {
          productNo: this.detailData.productNo
        }
      })
    },
    getDetail() {
      web_tourOrder_details({ orderId: this.orderId }).then(res => {
        this.detailData = res.writeOrderResponse
        // this.userNum = this.detailData.adultPopulation + this.detailData.childrenPopulation + this.detailData.childBedPopulation + this.detailData.agedPopulation
        for (let i = 0, l = this.detailData.adultPopulation; i < l; i++) {
          this.adultParams.push({
            touristName: '', // 姓名
            birthDate: '', // 生日
            touristGender: 0, // 性别
            certificatesType: 1, // 证件类型
            touristContactInformation: '', // 手机
            certificatesNumber: '', // 证件号码
            attachAmount: this.detailData.chargeResule.attachAdultAmount,
            buyerSettlementAmount: this.detailData.chargeResule.adultSingleAmount,
            confirmWay: this.confirmWay,
            crowdType: 1,
            depositAmount: this.detailData.chargeResule.adultSingleDepositAmount,
            orderId: this.orderId,
            orderSource: 1,
            teamId: this.tourTeamId,
            tourFeeAmount: this.detailData.chargeResule.adultSettlementPriceAmount
          })
          this.formList.push(`formAdult${i}`)
        }
        // 不占床儿童
        for (let i = 0, l = this.detailData.childrenPopulation; i < l; i++) {
          this.childrenParams.push({
            touristName: '', // 姓名
            birthDate: '', // 生日
            touristGender: 0, // 性别
            certificatesType: 1, // 证件类型
            touristContactInformation: '', // 手机
            certificatesNumber: '', // 证件号码
            attachAmount: this.detailData.chargeResule.attachChildAmount,
            buyerSettlementAmount: this.detailData.chargeResule.childrenSingleAmount,
            confirmWay: this.confirmWay,
            crowdType: 2,
            depositAmount: this.detailData.chargeResule.childrenSingleDepositAmount,
            orderId: this.orderId,
            orderSource: 1,
            teamId: this.tourTeamId,
            tourFeeAmount: this.detailData.chargeResule.childrenSettlementPriceAmount
          })
          this.formList.push(`formChildren${i}`)
        }
        // 占床儿童
        for (let i = 0, l = this.detailData.childBedPopulation; i < l; i++) {
          this.childBedParams.push({
            touristName: '', // 姓名
            birthDate: '', // 生日
            touristGender: 0, // 性别
            certificatesType: 1, // 证件类型
            touristContactInformation: '', // 手机
            certificatesNumber: '', // 证件号码
            attachAmount: this.detailData.chargeResule.attachChildBedAmount,
            buyerSettlementAmount: this.detailData.chargeResule.childrenBedSingleAmount,
            confirmWay: this.confirmWay,
            crowdType: 4,
            depositAmount: this.detailData.chargeResule.childrenBedSingleDepositAmount,
            orderId: this.orderId,
            orderSource: 1,
            teamId: this.tourTeamId,
            tourFeeAmount: this.detailData.chargeResule.childBedSettlementPriceAmount
          })
          this.formList.push(`formChildBed${i}`)
        }
        // 老人
        for (let i = 0, l = this.detailData.agedPopulation; i < l; i++) {
          this.agedParams.push({
            touristName: '', // 姓名
            birthDate: '', // 生日
            touristGender: 0, // 性别
            certificatesType: 1, // 证件类型
            touristContactInformation: '', // 手机
            certificatesNumber: '', // 证件号码
            attachAmount: this.detailData.chargeResule.attachAgedAmount,
            buyerSettlementAmount: this.detailData.chargeResule.agedSingleAmount,
            confirmWay: this.confirmWay,
            crowdType: 3,
            depositAmount: this.detailData.chargeResule.agedSingleDepositAmount,
            orderId: this.orderId,
            orderSource: 1,
            teamId: this.tourTeamId,
            tourFeeAmount: this.detailData.chargeResule.agedSettlementPriceAmount
          })
          this.formList.push(`formAged${i}`)
        }

        web_tourismFullReduction_orderReductionMsg({orderAmount: this.detailData.orderAmount}).then(res => {
          let resultAmount = res.result
          resultAmount.ruleArr = []
          if (resultAmount.rule) {
            resultAmount.ruleArr = resultAmount.rule.split('。')
          }
          this.resultAmount = resultAmount
          if (this.resultAmount.isReduction === 1) {
            this.resultPrice = (this.detailData.orderAmount - this.resultAmount.reductionAmount) > -1 ? this.detailData.orderAmount - this.resultAmount.reductionAmount : 0
          } else {
            this.resultPrice = this.detailData.orderAmount
          }
        })
      })
    },
    submit() {
      let status = []
      let path, productType = this.detailData.productType
      path = productType == 1 ? 'distributor-tour-back-tour-with-group-order-list' :
        productType == 2 ? 'distributor-tour-back-tour-independent-order-list' : 'distributor-tour-back-tour-local-order-list'
      for (let i = 0, l = this.formList.length; i < l; i++) {
        status.push(-1)
      }
      this.formList.map((value, index) => {
        this.$refs[value][0].validate((valid) => {
          valid ? status[index] = 1 : status[index] = -1
          if (status.indexOf(-1) === -1) {
            // 开始提交
            let params = []
            params = params.concat(this.adultParams).concat(this.childrenParams).concat(this.childBedParams).concat(this.agedParams)
            web_tourOrder_tourTraveller(params).then(res => {
              // 跳转
              this.$router.push({
                // name: 'distributor-tour-front-details',
                name: 'distributor-tour-front-pay',
                query: {
                  id: this.orderId,
                  type: 'pay',
                  superior: path
                  // productNo: this.detailData.productNo
                }
              })
            })
          }
        })
      })
    },
    back() {
      // 返回上一页
      this.$router.push({
        name: 'distributor-tour-front-reserve',
        query: {
          productNo: this.detailData.productNo,
          adultNumber: this.detailData.adultPopulation,
          childNumber: this.detailData.childrenPopulation,
          childBedNumber: this.detailData.childBedPopulation,
          agedNumber: this.detailData.agedPopulation,
          tourTeamId: this.tourTeamId,
          orderNo: this.detailData.orderNo
        }
      })
    },
    download() {
      web_tourOrder_tourTravellerExport({ url: this.excelUrl }).then(res => {
        this.fileUrl = res.url
        this.$nextTick(() => {
          this.$refs.downloadBtn.click()
        })
        // this.$refs.downloadBtn.click()
      })
    },
    beforeUploadImg(val) {
      let params = {
        adultNum: this.detailData.adultPopulation,
        childrenNum: this.detailData.childrenPopulation,
        childrenBedNum: this.detailData.childBedPopulation,
        agedNum: this.detailData.agedPopulation,
        upload: val
      }
      web_tourOrder_tourTravellerImport(params).then(res => {
        let list = res.list
        this.adultParams = []
        this.childrenParams = []
        this.childBedParams = []
        this.agedParams = []
        for (let i = 0, l = list.length; i < l; i++) {
          if (list[i][2] === '成人') {
            this.adultParams.push({
              touristName: list[i][0], // 姓名
              birthDate: list[i][6], // 生日
              touristGender: list[i][3] === '男' ? 0 : 1, // 性别
              certificatesType: this.changeCardType(list[i][4]), // 证件类型
              touristContactInformation: list[i][1], // 手机
              certificatesNumber: list[i][5], // 证件号码
              attachAmount: this.detailData.chargeResule.attachAdultAmount,
              buyerSettlementAmount: this.detailData.chargeResule.adultSingleAmount,
              confirmWay: this.confirmWay,
              crowdType: 1,
              depositAmount: this.detailData.chargeResule.adultSingleDepositAmount,
              orderId: this.orderId,
              orderSource: 1,
              teamId: this.tourTeamId,
              tourFeeAmount: this.detailData.chargeResule.adultSettlementPriceAmount
            })
          } else if (list[i][2] === '儿童不占床') {
            this.childrenParams.push({
              touristName: list[i][0], // 姓名
              birthDate: list[i][5], // 生日
              touristGender: list[i][3] === '男' ? 0 : 1, // 性别
              certificatesType: this.changeCardType(list[i][4]), // 证件类型
              touristContactInformation: list[i][1], // 手机
              certificatesNumber: list[i][5], // 证件号码
              attachAmount: this.detailData.chargeResule.attachChildAmount,
              buyerSettlementAmount: this.detailData.chargeResule.childrenSingleAmount,
              confirmWay: this.confirmWay,
              crowdType: 2,
              depositAmount: this.detailData.chargeResule.childrenSingleDepositAmount,
              orderId: this.orderId,
              orderSource: 1,
              teamId: this.tourTeamId,
              tourFeeAmount: this.detailData.chargeResule.childrenSettlementPriceAmount
            })
          } else if (list[i][2] === '儿童占床') {
            this.childBedParams.push({
              touristName: list[i][0], // 姓名
              birthDate: list[i][5], // 生日
              touristGender: list[i][3] === '男' ? 0 : 1, // 性别
              certificatesType: this.changeCardType(list[i][4]), // 证件类型
              touristContactInformation: list[i][1], // 手机
              certificatesNumber: list[i][5], // 证件号码
              attachAmount: this.detailData.chargeResule.attachChildBedAmount,
              buyerSettlementAmount: this.detailData.chargeResule.childrenBedSingleAmount,
              confirmWay: this.confirmWay,
              crowdType: 4,
              depositAmount: this.detailData.chargeResule.childrenBedSingleDepositAmount,
              orderId: this.orderId,
              orderSource: 1,
              teamId: this.tourTeamId,
              tourFeeAmount: this.detailData.chargeResule.childBedSettlementPriceAmount
            })
          } else {
            this.agedParams.push({
              touristName: list[i][0], // 姓名
              birthDate: list[i][5], // 生日
              touristGender: list[i][3] === '男' ? 0 : 1, // 性别
              certificatesType: this.changeCardType(list[i][4]), // 证件类型
              touristContactInformation: list[i][1], // 手机
              certificatesNumber: list[i][5], // 证件号码
              attachAmount: this.detailData.chargeResule.attachAgedAmount,
              buyerSettlementAmount: this.detailData.chargeResule.agedSingleAmount,
              confirmWay: this.confirmWay,
              crowdType: 3,
              depositAmount: this.detailData.chargeResule.agedSingleDepositAmount,
              orderId: this.orderId,
              orderSource: 1,
              teamId: this.tourTeamId,
              tourFeeAmount: this.detailData.chargeResule.agedSettlementPriceAmount
            })
          }
        }
      })
    },
    changeCardType(val) {
      let valNum = ''
      if (val === '身份证') {
        valNum = 1
      } else if (val === '护照') {
        valNum = 2
      } else if (val === '军官证') {
        valNum = 3
      } else if (val === '学生证') {
        valNum = 4
      } else {
        valNum = 5
      }
      return valNum
    },
    uploadChange(val) {
      console.log('v', val)
      let params = {
        adultNum: this.detailData.adultPopulation,
        childrenNum: this.detailData.childrenPopulation,
        childrenBedNum: this.detailData.childBedPopulation,
        agedNum: this.detailData.agedPopulation,
        upload: val.file
      }
      web_tourOrder_tourTravellerImport(params).then(res => {
        let adultNew = true
        let childNew = true
        let childBedNew = true
        let agedNew = true
        let list = res.list
        // this.adultParams = []
        // this.childrenParams = []
        // this.childBedParams = []
        // this.agedParams = []
        for (let i = 0, l = list.length; i < l; i++) {
          if (list[i][2] === '成人') {
            if (adultNew) {
              this.adultParams = []
              adultNew = false
            }
            this.adultParams.push({
              touristName: list[i][0], // 姓名
              birthDate: list[i][6], // 生日
              touristGender: list[i][3] === '男' ? 0 : 1, // 性别
              certificatesType: this.changeCardType(list[i][4]), // 证件类型
              touristContactInformation: list[i][1], // 手机
              certificatesNumber: list[i][5], // 证件号码
              attachAmount: this.detailData.chargeResule.attachAdultAmount,
              buyerSettlementAmount: this.detailData.chargeResule.adultSingleAmount,
              confirmWay: this.confirmWay,
              crowdType: 1,
              depositAmount: this.detailData.chargeResule.adultSingleDepositAmount,
              orderId: this.orderId,
              orderSource: 1,
              teamId: this.tourTeamId,
              tourFeeAmount: this.detailData.chargeResule.adultSettlementPriceAmount
            })
          } else if (list[i][2] === '儿童不占床') {
            if (childNew) {
              this.childrenParams = []
              childNew = false
            }
            this.childrenParams.push({
              touristName: list[i][0], // 姓名
              birthDate: list[i][5], // 生日
              touristGender: list[i][3] === '男' ? 0 : 1, // 性别
              certificatesType: this.changeCardType(list[i][4]), // 证件类型
              touristContactInformation: list[i][1], // 手机
              certificatesNumber: list[i][5], // 证件号码
              attachAmount: this.detailData.chargeResule.attachChildAmount,
              buyerSettlementAmount: this.detailData.chargeResule.childrenSingleAmount,
              confirmWay: this.confirmWay,
              crowdType: 2,
              depositAmount: this.detailData.chargeResule.childrenSingleDepositAmount,
              orderId: this.orderId,
              orderSource: 1,
              teamId: this.tourTeamId,
              tourFeeAmount: this.detailData.chargeResule.childrenSettlementPriceAmount
            })
          } else if (list[i][2] === '儿童占床') {
            if (childBedNew) {
              this.childBedParams = []
              childBedNew = false
            }
            this.childBedParams.push({
              touristName: list[i][0], // 姓名
              birthDate: list[i][5], // 生日
              touristGender: list[i][3] === '男' ? 0 : 1, // 性别
              certificatesType: this.changeCardType(list[i][4]), // 证件类型
              touristContactInformation: list[i][1], // 手机
              certificatesNumber: list[i][5], // 证件号码
              attachAmount: this.detailData.chargeResule.attachChildBedAmount,
              buyerSettlementAmount: this.detailData.chargeResule.childrenBedSingleAmount,
              confirmWay: this.confirmWay,
              crowdType: 4,
              depositAmount: this.detailData.chargeResule.childrenBedSingleDepositAmount,
              orderId: this.orderId,
              orderSource: 1,
              teamId: this.tourTeamId,
              tourFeeAmount: this.detailData.chargeResule.childBedSettlementPriceAmount
            })
          } else {
            if (agedNew) {
              this.agedParams = []
              agedNew = false
            }
            this.agedParams.push({
              touristName: list[i][0], // 姓名
              birthDate: list[i][5], // 生日
              touristGender: list[i][3] === '男' ? 0 : 1, // 性别
              certificatesType: this.changeCardType(list[i][4]), // 证件类型
              touristContactInformation: list[i][1], // 手机
              certificatesNumber: list[i][5], // 证件号码
              attachAmount: this.detailData.chargeResule.attachAgedAmount,
              buyerSettlementAmount: this.detailData.chargeResule.agedSingleAmount,
              confirmWay: this.confirmWay,
              crowdType: 3,
              depositAmount: this.detailData.chargeResule.agedSingleDepositAmount,
              orderId: this.orderId,
              orderSource: 1,
              teamId: this.tourTeamId,
              tourFeeAmount: this.detailData.chargeResule.agedSettlementPriceAmount
            })
          }
        }
        this.$refs.excelUpload.clearFiles()
      }).catch(() => {
        this.$refs.excelUpload.clearFiles()
      })
    },
    changeAdultCardType(index, val) {
      this.$nextTick(() => {
        this.$refs[`formAdult${index}`][0].clearValidate('touristName')
      })
    },
    changeChildBedCardType(index, val) {
      this.$nextTick(() => {
        this.$refs[`formChildBed${index}`][0].clearValidate('touristName')
      })
    },
    changeChildCardType(index, val) {
      this.$nextTick(() => {
        this.$refs[`formChildren${index}`][0].clearValidate('touristName')
      })
    },
    changeAgedCardType(index, val) {
      this.$nextTick(() => {
        this.$refs[`formAged${index}`][0].clearValidate('touristName')
      })
    },
  }
}

/*
* attachAmount: "0"
birthDate: "2020-01-07"
buyerSettlementAmount: "2999"
certificatesNumber: "442000199112122337"
certificatesType: "1"
confirmWay: "2"
crowdType: "1"
depositAmount: "1500"
orderId: "984"
orderSource: 1
teamId: "1022468"
tourFeeAmount: "2999"
touristContactInformation: "13513366467"
touristGender: "0"
touristName: "小明"*/
