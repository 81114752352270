// web_tourOrder_tourTravellerImport 出游人导入

const __request = require(`./__request/__request_contentType_form`);

// 接口地址： http://192.168.0.15:8080/doc.html#/所有接口/tour-order-controller/tourTravellerImportUsingPOST_1


export default function web_tourOrder_tourTravellerImport(pParameter) {
  if (!pParameter) pParameter = {};
  let formData = new FormData()
  formData.append('adultNum', pParameter.adultNum)
  formData.append('childrenNum', pParameter.childrenNum)
  formData.append('childrenBedNum', pParameter.childrenBedNum)
  formData.append('agedNum', pParameter.agedNum)
  formData.append('upload', pParameter.upload)
  return __request({
    urlSuffix: '/web/tourOrder/tourTravellerImport',
    data: formData,
    // process_response_data: false,
    transformRequest: [function (data, headers) {
      return data;
    }],
  });
}
